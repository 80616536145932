import { Container, Typography, Box, Grid } from '@mui/material';

export default function PrivacyPolicyPage() {
    return (
        <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', mt: 20, mb: 8 }}>
                <Grid container justifyContent="center">
                    <Grid item>
                        <Typography variant="h4" align="center" sx={{ mb: 8 }}>
                            GradGuide Privacy Policy
                        </Typography>

                    </Grid>
                </Grid>
                <Container maxWidth="md">
                    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
                        Effective date: 01/06/2024
                    </Typography>
                    <Typography variant="body1" component="p" sx={{ mt: 1, mb: 5 }}>
                        Welcome to GradGuide (the "Platform"). We are committed to protecting your personal data and respecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website, gradguide.nl, in compliance with the General Data Protection Regulation (GDPR).
                    </Typography>


                    <Typography mb={4}>
                        <Typography component="ol"
                            sx={{
                                pl: 2,
                                listStyleType: 'decimal',
                                '& li': {
                                    typography: 'h4',
                                    display: 'list-item',
                                    '&::marker': {
                                        fontWeight: 'bold',
                                    },
                                },
                            }}
                        >
                            <li>
                                Contact Information
                                <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                    https://GradGuide.nl
                                </Typography>
                                <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                    GradGuide_Privacy@Gmail.com
                                </Typography>
                            </li>

                            <li>
                                Information We Collect
                                <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                    We may collect and process the following data about you:
                                </Typography>
                                <Typography variant="body1" component="div" sx={{
                                    '& li': {
                                        typography: 'body1',
                                        display: 'list-item',
                                        '&::marker': {
                                            fontWeight: 'bold',
                                        },
                                    },
                                }}>
                                    <ul>
                                        <li>Automatically collected information:</li>
                                        <Typography variant="body1" component="div" sx={{
                                            '& li': {
                                                typography: 'body1',
                                                display: 'list-item',
                                                '&::marker': {
                                                    fontWeight: 'bold',
                                                },
                                            },
                                        }}>
                                            <ul>
                                                <li>IP-Address.</li>
                                                <li>Geo-location information.</li>
                                                <li>Usage data.</li>
                                                <li>Browser type and version.</li>
                                                <li>Device type.</li>
                                                <li>
                                                    Cookies and similar technologies: GradGuide uses only technical and functional cookies, and analytical cookies that do not infringe on your privacy. A cookie is a small text file that is stored on your computer, tablet, or smartphone during your first visit to this website. The cookies we use are necessary for the technical operation of the website and your ease of use. They ensure that the website functions properly and remember your preferred settings, for example. We can also optimize our website with these cookies. You can opt out of cookies by setting your internet browser to no longer store cookies. Additionally, you can also delete all previously stored information through your browser settings.
                                                </li>
                                            </ul>
                                        </Typography>
                                        <li>Information we collect when you create an Account:</li>
                                        <Typography variant="body1" component="div" sx={{
                                            '& li': {
                                                typography: 'body1',
                                                display: 'list-item',
                                                '&::marker': {
                                                    fontWeight: 'bold',
                                                },
                                            },
                                        }}>
                                            <ul>
                                                <li>First and last name.</li>
                                                <li>Email address.</li>
                                                <li>Phone number.</li>
                                                <li>Marketing and communications data.</li>
                                            </ul>
                                        </Typography>
                                        <li>Other information that you actively provide by filling in our survey:</li>
                                        <Typography variant="body1" component="div" sx={{
                                            '& li': {
                                                typography: 'body1',
                                                display: 'list-item',
                                                '&::marker': {
                                                    fontWeight: 'bold',
                                                },
                                            },
                                        }}>
                                            <ul>
                                                <li>Job or career preferences.</li>
                                                <li>Information about your education.</li>
                                                <li>Language proficiencies.</li>
                                            </ul>
                                        </Typography>
                                        <li>Professional information such as a resume that you actively provide by opting to connect with companies:</li>
                                    </ul>
                                </Typography>
                            </li>


                            <li>How We Use Your Information</li>
                            <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                We use the information we collect in the following ways:
                            </Typography>

                            <Typography variant="body1" component="div" sx={{
                                '& li': {
                                    typography: 'body1',
                                    display: 'list-item',
                                    '&::marker': {
                                        fontWeight: 'bold',
                                    },
                                },
                            }}>
                                <ul>
                                    <li>To provide, operate, and maintain our Platform.</li>
                                    <li>To improve, personalize, and expand our Platform.</li>
                                    <li>To understand and analyze how you use our Platform to improve our platform and offering and to customize these to your preferences.</li>
                                    <li>To develop new products, services, features, and functionality.</li>
                                    <li>To connect students with recruiters and facilitate the recruitment process.</li>
                                    <li>To communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the Platform, and for marketing and promotional purposes.</li>
                                    <li>To contact you through e-mail or via telephone where necessary to provide our services.</li>
                                    <li>To inform you of any changes in our services or products.</li>
                                    <li>To allow you to create an Account on our platform.</li>
                                    <li>To deliver our services.</li>
                                    <li>To find and prevent fraud.</li>
                                    <li>To comply with legal obligations.</li>
                                </ul>
                            </Typography>


                            <li>Legal Basis for Processing Personal Data</li>
                            <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                We rely on the following legal bases to process your personal data:
                                <Typography variant="body1" component="div" sx={{
                                    '& li': {
                                        typography: 'body1',
                                        display: 'list-item',
                                        '&::marker': {
                                            fontWeight: 'bold',
                                        },
                                    },
                                }}>
                                    <ul>
                                        <li>Consent: We may process your data if you have given us specific consent to use your personal information to deliver our services.</li>
                                        <li>Legal obligation: We may process your data where we are legally required to do so.</li>
                                    </ul>
                                </Typography>
                            </Typography>


                            <li>Sharing Your Information</li>
                            <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                We do not share your personal data with third parties except in the following circumstances:
                            </Typography>
                            <Typography variant="body1" component="div" sx={{
                                '& li': {
                                    typography: 'body1',
                                    display: 'list-item',
                                    '&::marker': {
                                        fontWeight: 'bold',
                                    },
                                },
                            }}>
                                <ul>
                                    <li>Service delivery:</li>
                                    <Typography variant="body1" component="div" sx={{
                                        '& li': {
                                            typography: 'body1',
                                            display: 'list-item',
                                            '&::marker': {
                                                fontWeight: 'bold',
                                            },
                                        },
                                    }}>
                                        <ul>
                                            <li>To fulfill the proposition that we offer Job Seekers, when you connect with an Employer by clicking 'I'm interested'.</li>
                                            <li>To fulfill the proposition that we offer recruiters/companies.</li>
                                        </ul>
                                    </Typography>
                                    <li>Legal obligation: We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
                                    <li>
                                        Business transfers: We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                                    </li>
                                    <li>
                                        Service providers: We may share your data with third-party service providers who perform services on our behalf.
                                    </li>
                                    <li>
                                        Social media platforms: Where permissible according to applicable law we may use certain limited Personal Information about you, such as your email address, to share it with social media platforms, such as Facebook, Google, or LinkedIn, to generate leads, drive traffic to our websites or otherwise promote our products and services of GradGuide. These processing activities are based on our legitimate interest in undertaking marketing activities to offer you products or services that may be of your interest.
                                    </li>
                                    <li>
                                        Aggregated Information: We may share aggregated information (i.e., data about our users that we compile in a manner that no longer identifies or references any individual user) and other anonymized information. This is used for purposes such as regulatory compliance, industry and market analysis, demographic profiling, marketing and advertising, and other business purposes, like creating reports with aggregated insights for Employers. This aggregated information does not identify you.
                                    </li>

                                </ul>
                            </Typography>

                            <li>Your Data Protection Rights</li>
                            <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                Under the GDPR, you have the following rights:
                                <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                    <Typography variant="body1" component="div" sx={{
                                        '& li': {
                                            typography: 'body1',
                                            display: 'list-item',
                                            '&::marker': {
                                                fontWeight: 'bold',
                                            },
                                        },
                                    }}>
                                        <ul>
                                            <li>
                                                Right to Access: You have the right to request copies of your personal data.
                                            </li>
                                            <li>
                                                Right to Rectification: You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.
                                            </li>
                                            <li>
                                                Right to Erasure: You have the right to request that we erase your personal data, under certain conditions.
                                            </li>
                                            <li>
                                                Right to Restrict Processing: You have the right to request that we restrict the processing of your personal data, under certain conditions.
                                            </li>
                                            <li>
                                                Right to Object to Processing: You have the right to object to our processing of your personal data, under certain conditions.
                                            </li>
                                            <li>
                                                Right to Data Portability: You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
                                            </li>

                                        </ul>
                                    </Typography>
                                    <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                        If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our contact information provided above.
                                    </Typography>
                                    <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                        To ensure that the request for access was made by you, we ask that you include a copy of your ID with the request. In this copy, please black out your photo, MRZ (machine readable zone, the strip of numbers at the bottom of the passport), passport number, and Citizen Service Number (BSN). This is to protect your privacy.
                                    </Typography>
                                </Typography>


                                <li>Minor's and Children's Privacy</li>
                                <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>Our website and/or service does not intend to collect data about website visitors who are under 16 years old, unless they have permission from their parents or guardian. However, we cannot verify whether a Visitor is over 16. Therefore, we recommend that parents be involved in their children's online activities to prevent data from being collected about children without parental consent. If you believe that we have collected personal data about a minor without such permission, please contact us and we will delete this information.</Typography>
                                

                                <li>Data Security</li>
                                <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                    How we protect personal data GradGuide takes the protection of your data seriously and takes appropriate measures to prevent misuse, loss, unauthorized access, unwanted disclosure, and unauthorized modification. If you have the impression that your data is not properly secured or there are indications of misuse, please contact us at our contact information provided above.
                                </Typography>
                                <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                    While we have taken reasonable steps to secure the personal information you provide to us, please be aware that no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
                                </Typography>


                                <li>Data Retention</li>
                                <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                    We will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy, unless a longer retention period is required or permitted by law.
                                </Typography>


                                <li>Changes to This Privacy Policy</li>
                                <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                    We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the effective date. You are advised to review this Privacy Policy periodically for any changes.
                                </Typography>


                            </Typography>
                        </Typography>
                    </Typography>



                </Container>
        </Box>
    );
}